var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2"},[_c('i',{staticClass:"text-primary ti ti-school small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v(" Daftar Request Pelatihan ")])]),_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('div',{staticClass:"align-items-end"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm","to":{
                      name: _vm.checkAuthorizeRole('training-requests.create'),
                    }}},[_c('i',{staticClass:"ti ti-plus small-gap-right"}),_vm._v(" Tambah ")])],1)])],1)],1),_c('vue-good-table',{attrs:{"mode":"remote","is-loading":_vm.isLoading,"total-rows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.rows,"sort-options":{
              enabled: true,
              initialSortBy: { field: 'name', type: 'asc' },
            },"select-options":{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              nextLabel: 'selanjutnya',
              prevLabel: 'sebelumnya',
              rowsPerPageLabel: 'Data per halaman',
              ofLabel: 'dari',
              pageLabel: 'halaman', // for 'pages' mode
              allLabel: 'Semua',
            },"line-numbers":true},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'user_name')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.user_avatar,"text":_vm.avatarText(props.row.user_name),"variant":"light-primary"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.user_name))])],1):(props.column.field === 'status')?_c('span',{staticClass:"d-flex justify-content-start"},[_c('b-badge',{staticClass:"small-gap-right",attrs:{"variant":_vm._f("statusClass")(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):(props.column.field === 'action')?_c('span',{staticClass:"d-flex justify-content-start"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","size":"sm","to":{
                    name: _vm.checkAuthorizeRole('training-requests.show'),
                    params: {
                      trainingRequestId: props.row.id
                    }
                  }}},[_c('i',{staticClass:"ti ti-pencil"})]),((_vm.user.id === props.row.created_by_id))?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.handleDelete(props.row)}}},[_c('i',{staticClass:"ti ti-trash"})]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }